import axios, { AxiosRequestConfig } from 'axios';
import React, { useState, FC, ReactElement } from 'react';

import './SearchWidget.scss';

interface ITestedVRM {
  isValid: boolean;
  cleanedReg: string;
}

interface ITestedPostcode {
  isValid: boolean;
  cleanedPostcode: string;
}

const { GATSBY_ENVIRONMENT_STATUS } = process.env;

const SearchWidget: FC = (): ReactElement => {
  const [openTab, setOpenTab] = useState<number>(1);
  const [vrm, setVrm] = useState<string>(``);
  const [postcode, setPostcode] = useState<string>(``);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [vrmError, setVrmError] = useState<boolean>(false);
  const [postcodeError, setPostcodeError] = useState<boolean>(false);
  const [vrmErrorMessage, setVrmErrorMessage] = useState<string>(``);
  const [postcodeErrorMessage, setPostcodeErrorMessage] = useState<string>(``);

  // Main function to deal with the push 
  const startJourney = async (vrm: string, postcode: string): Promise<void> => {
    
    // this checks if dev and then pushes to the correct live or dev vesion of the APP
    const urlApp =
      GATSBY_ENVIRONMENT_STATUS === `develop`
        ? `https://app-dev.motokiki.com/find/wheels/`
        : `https://app.motokiki.com/find/wheels/`;

    // this checks if dev and then pushes to the correct live or dev vesion of the API
    const urlApi =
      GATSBY_ENVIRONMENT_STATUS === `develop`
        ? `https://api-dev.motokiki.com/journey/startJourney`
        : `https://api.motokiki.com/journey/startJourney`;

    // This is to test the main motokiki API when running locally
    // const urlApi =
    //   GATSBY_ENVIRONMENT_STATUS === `develop`
    //     ? `http://localhost:4000/journey/startJourney`
    //     : null;

    setIsLoading(true);

    try {
      const body = {
        vrm,
        postcode,
      };

      const headers = {
        'Content-Type': `application/json`,
      };

      const config: AxiosRequestConfig = {
        method: `POST`,
        url: urlApi,
        headers,
        data: body,
      };

      const result = await axios(config);

      if (result) {
        location.href = urlApp + result.data;
        setIsLoading(false);
        setVrm(``);
        setPostcode(``);
      }
    } catch (err) {
      console.error(err);
      setIsLoading(false);
      setVrm(``);
      setPostcode(``);
    }
  };

  // function to clean the postcode/vrm and change to uppercase
  const removeSpacesAndCapitalise = (string: string) =>
    string.replace(/ /g, ``).toUpperCase();

  // can't store the regex in global state as it throws errors storing them in a function and running the function works
  const testRegexScript = (scriptString: string): boolean => {
    // regex to make sure no one pushing in code
    const regexScript: any = /<script>/gi;
    return regexScript.test(scriptString);
  };

  const testRegexVrm = (vrmString: string): boolean => {
    // regex to test if vechicle reg is correct
    const regexVrm: any = /^(\s*)(([A-Za-z]{1,2}[ ]?[0-9]{1,4})|([A-Za-z]{3}[ ]?[0-9]{1,3})|([0-9]{1,3}[ ]?[A-Za-z]{3})|([0-9]{1,4}[ ]?[A-Za-z]{1,2})|([A-Za-z]{3}[ ]?[0-9]{1,3}[ ]?[A-Za-z])|([A-Za-z][ ]?[0-9]{1,3}[ ]?[A-Za-z]{3})|([A-Za-z]{2}[ ]?[0-9]{2}[ ]?[A-Za-z]{3})|([A-Za-z]{3}[ ]?[0-9]{4}))(\s*)$/gi;
    return regexVrm.test(vrmString);
  };

  const testRegexPostcode = (postcodeString: string): boolean => {
    // regex to test if the postcode is valid
    const regexPostcode: any = /^[A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}$/gi;
    return regexPostcode.test(postcodeString);
  };

  // error handling function
  const clearErrorState = (errInput: string) => {
    if (errInput === `vrm`) {
      setVrmError(false);
      setVrmErrorMessage(``);
    } else {
      setPostcodeError(false);
      setPostcodeErrorMessage(``);
    }
  };

  // validate postcode before pushing the user on the new journey
  const validatePostcode = (testPostcode: string): ITestedPostcode => {
    const testForScript = testRegexScript(testPostcode);
    if (!testPostcode || testForScript) {
      console.error(`no postcode entered`);
      setPostcodeError(true);
      setPostcodeErrorMessage(`Empty Postcode`);
      return {
        isValid: false,
        cleanedPostcode: `i have failed`,
      };
    }
    const cleanedPostcode = removeSpacesAndCapitalise(testPostcode);
    const correctlyFormattedPostcode = testRegexPostcode(cleanedPostcode);
    if (!correctlyFormattedPostcode) {
      console.error(`invalid Postcode`);
      setPostcodeError(true);
      setPostcodeErrorMessage(`Invalid postcode, please enter valid postcode`);
    }
    return {
      isValid: correctlyFormattedPostcode,
      cleanedPostcode,
    };
  };

  // validate vrm before pushing the user on the new journey
  const validateVrm = (testVrm: string): ITestedVRM => {
    const testForScript = testRegexScript(testVrm);
    if (!testVrm || testForScript) {
      console.error(`no vrm entered`);
      setVrmError(true);
      setVrmErrorMessage(`Empty Vrm`);
      return {
        isValid: false,
        cleanedReg: `i have failed`,
      };
    }
    const cleanedReg = removeSpacesAndCapitalise(testVrm);
    const correctlyFormattedReg = testRegexVrm(testVrm);

    if (!correctlyFormattedReg) {
      console.error(`invalid Reg number`);
      setPostcodeError(true);
      setPostcodeErrorMessage(
        `Invalid registration, please enter valid registration number`,
      );
    }
    return {
      isValid: correctlyFormattedReg,
      cleanedReg,
    };
  };

  const handleSubmit = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): void => {
    e.preventDefault();
    const checkVrm = validateVrm(vrm);
    const checkPostcode = validatePostcode(postcode);

    if (
      checkVrm.isValid &&
      checkVrm.cleanedReg &&
      checkPostcode.cleanedPostcode &&
      checkPostcode.isValid
    ) {
      startJourney(checkVrm.cleanedReg, checkPostcode.cleanedPostcode);
    } else if (!checkVrm.isValid) {
      setVrmError(true);
    } else if (!checkPostcode.isValid) {
      setPostcodeError(true);
    }
  };

  // handles the postcode only search when searching by trye size
  const handlePostcodeOnlySubmit = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): void => {
    e.preventDefault();

    const checkPostcode = validatePostcode(postcode);

    if (checkPostcode.cleanedPostcode && checkPostcode.isValid) {
      // OFR passed in empty string for the vrm value and has to be in this order VRM then Postcode
      startJourney(``, checkPostcode.cleanedPostcode);
    } else if (!checkPostcode.isValid) {
      setPostcodeError(true);
    }
  };

  const showTabsJsx = (): ReactElement => {
    const spinner = isLoading ? `spinner` : ``;

    if (openTab === 1) {
      return (
        <div id="tabPostcode" className="tabContent">
          <form id="postCodeForm" className="postCodeform">
            <input
              className="regNo"
              value={vrm.toUpperCase()}
              onChange={(event) => {
                setVrm(event.target.value);
                if (vrmError) {
                  clearErrorState(`vrm`);
                }
              }}
              type="text"
              placeholder="ENTER REG"
            />
            {vrmError ? (
              <p className="vrmErrorMessage">{vrmErrorMessage}</p>
            ) : null}
            <input
              className="postCode"
              value={postcode.toUpperCase()}
              onChange={(event) => {
                setPostcode(event.target.value);
                if (postcodeError) {
                  clearErrorState(`postcode`);
                }
              }}
              type="text"
              placeholder="ENTER POSTCODE"
            />
            {postcodeError ? <p>{postcodeErrorMessage}</p> : null}
            <button
              className={`btnHome ${isLoading ? `hidden` : ``}`}
              onClick={(event) => {
                handleSubmit(event);
              }}
              type="submit"
            >
              Find and buy tyres
            </button>
            <button
              className={`${spinner} ${isLoading ? `` : `hidden`}`}
              onClick={(event) => {
                handleSubmit(event);
              }}
              type="submit"
            >
              Finding your tyres
            </button>
          </form>
        </div>
      );
    }
    return (
      <div id="tabTyreSize" className="tabContent">
        <img
          src="https://motorkiki-rebrand-static-assets.s3.eu-west-2.amazonaws.com/images/misc/tyreSizePlain.png"
          alt="tyre diagram"
          className="tabTyreImg"
        />
        <form id="tyreSizeform" className=" tyreSizeform">
          <input
            className="postCode"
            value={postcode.toUpperCase()}
            onChange={(event) => {
              setPostcode(event.target.value);
              if (postcodeError) {
                clearErrorState(`postcode`);
              }
            }}
            type="text"
            placeholder="ENTER POSTCODE"
          />
          {postcodeError ? <p>{postcodeErrorMessage}</p> : null}
          <button
            className={`btnHome ${isLoading ? `hidden` : ``}`}
            type="submit"
            onClick={(event) => {
              handlePostcodeOnlySubmit(event);
            }}
          >
            Find and buy tyres
          </button>
          <button
            className={`${spinner} ${isLoading ? `` : `hidden`}`}
            type="button"
          >
            Finding your tyres
          </button>
        </form>
      </div>
    );
  };

  return (
    <>
      <div className="search-widget" {...openTab}>
        <ul className="tabs">
          <li id="tabsPostcode" onClick={() => setOpenTab(1)}>
            <p className={`${openTab === 1 ? `activeTab` : ``}`}>
              {` `}
              Search postcode
            </p>
          </li>
          <li id="tabsTyreSize" onClick={() => setOpenTab(2)}>
            <p className={`${openTab === 2 ? `activeTab` : ``}`}>
              {` `}
              Search tyre size
            </p>
          </li>
        </ul>
        {showTabsJsx()}
      </div>
    </>
  );
};

export default SearchWidget;
